export const LocationPinIcon = () => (
  <svg
    width="16"
    focusable="false"
    height="24"
    viewBox="0 0 16 24"
    className={`svg-icon location-pin-icon`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.802 0 0 3.403 0 7.602 0 11.8 3.469 16.812 8 24c4.531-7.188 8-12.2 8-16.398C16 3.403 12.199 0 8 0zM5 8a3 3 0 116 0 3 3 0 01-6 0z"
      fill="#D63930"
    />
  </svg>
);

import { inject } from "mobx-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { reverseRoute } from "@inferno/renderer-shared-core";
import { Container, MagicLink } from "../../ui";
import { ArrowIcon } from "../icons/ArrowIcon.component";
import "./TrafficTile.style.scss";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { LocationPinIcon } from "../../components/icons/LocationPinIcon.component";
import classnames from "classnames";
import type { Store } from "@inferno/renderer-shared-core";
import { COMPONENT_BREAKPOINTS } from "../../ui/constants";
import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";

interface TrafficProps {
  block: BlockFragment;
  store?: Store;
}

export const TrafficTile = inject("store")(({ store, block }: TrafficProps) => {
  if (!store) {
    return null;
  }
  const { site } = store;
  const { t } = useTranslation();
  const url = reverseRoute(site, "traffic") || "#";
  useEffect(() => store.storeBlock({ ...block }));

  const { tags } = block;
  const css = classnames("component-traffic-tile", {
    [`themed-block`]: tags?.includes("display-hints/themed-block"),
  });

  const imgUrl = "https://i.iheart.com/v3/re/new_assets/627ed0263f19c6325b37ec16";
  let width = 100;
  let height = 70;

  const [isDesktop, setIsDesktop] = useState(false);

  const handleWindowResize = () => {
    if (window.innerWidth > COMPONENT_BREAKPOINTS.mobileBreakpoint) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  };
  if (isDesktop) {
    width = 235;
    height = 170;
  }

  useEffect(() => {
    if (isWindowDefined()) {
      handleWindowResize();
      resizeWatcher.onWidthChange.subscribe(handleWindowResize);
      return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={css} block={false}>
      <section className="traffic-block">
        <div className="tile-container">
          <MagicLink to={url}>
            <span className="title">{t("trafficTile")}</span>
          </MagicLink>
        </div>
        <MagicLink aria-label="Arrow icon" className="icon-arrow" to={url}>
          <ArrowIcon />
        </MagicLink>
        <div className="local-traffic-map">
          <MagicLink to={url}>
            <img width={width} height={height} src={imgUrl} alt={"Map of Traffic Conditions"} />
            <LocationPinIcon />
          </MagicLink>
        </div>
      </section>
    </Container>
  );
});

export default TrafficTile;
